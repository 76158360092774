/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import { useState } from 'preact/hooks';
import { camelizeKeys } from 'humps';
import titleize from '../../utils/titleize';
import { selectIcon } from '../../templates/place';
import SelectContext from './context';
import { formatCityDisplay } from './displayTypes';
import LabelsNearestLocation from '../LabelsNearestLocation';
import { Fragment } from 'preact';

const SelectElement = ({ place, position, flatVariant, showNearest }) => {
  const [hover, setHover] = useState(false);

  const handleElementClick = (field, onChange, handleElementSelect) => {
    onChange(field, {
      ...camelizeKeys(place),
      position,
    });
    handleElementSelect();
  };

  const cityDisplayType = isPackagesSearch => (
    <div className="place-info">{formatCityDisplay(isPackagesSearch, place)}</div>
  );

  const stateDisplayType = () => (
    <>
      <div
        className="autocomplete-icon"
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: selectIcon(place.result_type, false),
        }}
      />
      <div className={flatVariant ? 'place-info-column' : 'place-info-classic'}>
        <div className="place-info">
          <b>{`${titleize(place.display)}, `}</b>
          {titleize(place.state)}
        </div>

        {showNearest && (
          <LabelsNearestLocation flatVariant={flatVariant} locationCopy="nearest_location" />
        )}
      </div>
    </>
  );

  const cityStateDisplayType = () => (
    <>
      <div
        className="autocomplete-icon"
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: selectIcon('pin-solid', false),
        }}
      />
      <div className="place-info-classic">
        <div className="place-info">
          <b>{`${titleize(place.city_name)}, `}</b>
          {`${titleize(place.state)} ${titleize(place.display)}`}
        </div>

        {showNearest && (
          <LabelsNearestLocation flatVariant={flatVariant} locationCopy="nearest_location" />
        )}
      </div>
    </>
  );

  const terminalCityDisplayType = () => (
    <>
      <div
        className="autocomplete-icon"
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: selectIcon('pin-solid', false),
        }}
      />
      <div className="place-info-classic">
        <div className="place-info">
          <b>{`${titleize(place.display)}, `}</b>
          {`${titleize(place.city_name)}`}
        </div>

        {showNearest && (
          <LabelsNearestLocation flatVariant={flatVariant} locationCopy="nearest_location" />
        )}
      </div>
    </>
  );

  const onlyTerminalDisplayType = () => (
    <>
      <div
        className="autocomplete-icon"
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: selectIcon('pin-solid', false),
        }}
      />
      <div className="place-info-classic">
        <div className="place-info">{titleize(place.display)}</div>

        {showNearest && (
          <LabelsNearestLocation flatVariant={flatVariant} locationCopy="nearest_location" />
        )}
      </div>
    </>
  );

  const renderDisplayType = (displayType, isPackagesSearch) => {
    console.log('Debug DT', displayType);
    switch (displayType) {
      case 'city':
        return cityDisplayType(isPackagesSearch);

      case 'state':
        return stateDisplayType();

      case 'city_state':
        return cityStateDisplayType();

      case 'terminal_city':
        return terminalCityDisplayType();

      case 'only_terminal':
        return onlyTerminalDisplayType();

      default:
        return stateDisplayType();
    }
  };

  return (
    <SelectContext.Consumer>
      {value => {
        return (
          <li
            className={`es-visible ${hover ? 'selected' : ''}`}
            style="display: flex;"
            onClick={() =>
              handleElementClick(value.field, value.onChange, value.handleElementSelect)
            }
            onMouseOver={() => setHover(true)}
            onFocus={() => setHover(true)}
            onMouseOut={() => setHover(false)}
            onBlur={() => setHover(false)}
          >
            {renderDisplayType(value.displayType, value.isPackagesSearch)}
          </li>
        );
      }}
    </SelectContext.Consumer>
  );
};

export default SelectElement;
