/* global $ */
import dayjs from 'dayjs';
import { Component } from 'preact';
import { IntlProvider } from 'preact-i18n';
import { getGrowthBookFeatures, initGrowthBook } from '../utils/growthbook';
import AutocompleteTypeahead from 'async!./AutocompleteTypeahead';
import AutocompleteSelect from 'async!./AutoCompleteSelect';
import translations, { supported as supportedLang, DEFAULT_LANG } from '../translations/i18n';
import datepickerTranslations from '../translations/datepicker';
import dayjsTranslations from '../translations/dayjs';
import widgetConfig from '../../config';
import 'script-loader!pickadate/lib/compressed/picker';
import 'script-loader!pickadate/lib/compressed/picker.date';
import { initTest, getTest, emitTest } from '../utils/abtest';
import createUrl from '../utils/createUrl';
import Tabs from './Tabs';
import Tab from './Tab';
import HotelSearch from './HotelSearch';
import Search from './search';
import PackagesSearch from './PackagesSearch';
// eslint-disable-next-line import/extensions
import '../../config/pickadate.js';
import UserPreferencesProvider from '../context/userPreferences/UserPreferencesProvider';
import { WidgetConfigProvider } from '../context/WidgetConfig';
import { GrowthBookFeaturesProvider } from '../context/GrowthBook';
import { getPassengersSelectorList } from '../utils/encodePassengers.js';
import { decodePassengersValues } from '../utils/decodePassengers.js';

const GROWTHBOOK_FEATURES = [
  'funnel_version',
  'search_passengers',
  'enable_categories',
  'results_seats',
];

class SearchHub extends Component {
  abtest = 'a';

  state = {
    activeTab: 0,
  };

  constructor(props) {
    super(props);
    this.growthbookFeatures = GROWTHBOOK_FEATURES;
    this.createTabsInfo = this.createTabsInfo.bind(this);
    this.initTest();
    this.initLanguage();
    this.loadGrowthbook = this.loadGrowthbook.bind(this);
    this.setAvailableGrowthbookFeatures = this.setAvailableGrowthbookFeatures.bind(this);
    this.thirdPartyInitializations = this.thirdPartyInitializations.bind(this);
    this.portalCreation();
  }

  componentDidMount() {
    this.trackerInitialized();
  }

  portalCreation() {
    const { accentColor, buttonColor, buttonBg } = this.props;
    if (document.getElementById('reservamosPortal')) return;
    const reservamosPortal = document.createElement('div');
    reservamosPortal.id = 'reservamosPortal';
    reservamosPortal.className = 'reservamos-search';
    reservamosPortal.style.setProperty('--accent-500', accentColor);
    reservamosPortal.style.setProperty('--button-color', buttonColor);
    reservamosPortal.style.setProperty('--button-bg', buttonBg);
    document.body.appendChild(reservamosPortal);
  }

  // Initialize the tracker checking.
  trackerInitialized() {
    if (window.mixpanel && window.mixpanel.__loaded) {
      this.thirdPartyInitializations();
    } else {
      window.addEventListener('MixpanelReady', () => {
        this.thirdPartyInitializations();
      });
    }
  }

  // Initializes all that needs the tracker initialized.
  thirdPartyInitializations() {
    this.loadGrowthbook();
  }

  /**
   * Initializes the growthbook instance with the features
   */
  loadGrowthbook() {
    const { brand, lang, growthbook, growthbookStandalone, growthbookDev } = this.config;
    if (!growthbook) return;
    if (growthbookStandalone) {
      initGrowthBook(brand, growthbook, growthbookDev, lang).then(() => {
        this.setAvailableGrowthbookFeatures();
      });
      return;
    }
    if (window.growthBook) {
      this.setAvailableGrowthbookFeatures();
    } else {
      window.addEventListener('GrowthBookReady', () => {
        this.setAvailableGrowthbookFeatures();
      });
    }
  }

  setConfig() {
    const growthbookFeaturesEnabled = this.growthbookFeatures;
    const {
      airline,
      transporter,
      autocompleteType = 'typeahead',
      accentColor = null,
      brandingCopy = null,
      funnelUrl = 'viaje.reservamos.mx',
      line,
      maxDaysSearch = '30',
      calendarOpen = 'false',
      allowHybridTrip = 'false',
      optInReturn = 'true',
      openNewWindow = 'false',
      passengersDropdown = 'true',
      buttonColor = null,
      buttonBg = null,
      showErrors = 'false',
      useGtm = 'false',
      utmCampaign,
      utmMedium,
      utmSource,
      utmContent,
      widgetLayout = '',
      widgetTitle = '',
      forwardQuery = 'false',
      useUtmFor = 'bus',
      showOpenTicket = 'false',
      sourceUrl = 'www.reservamos.mx/api/v2/places',
      searchTypes = 'bus',
      hotelsUrl = 'travel.resertravel.com',
      packagesUrl = 'paquetes.resertravel.com',
      hotelsResultsType = 'list',
      displayType = 'city',
      departureDate,
      destination,
      origin,
      passengers,
      returnDate,
      multiOpenTicket = 'false',
      forceHttps = null,
      lang = 'spanish',
      autoSelectOrigin = false,
      cardsAvailable,
      oxxoAvailable,
      paypalAvailable,
      coppelpayAvailable,
      discountSingle = '',
      discountRounded = '',
      discountOpenTicket = '',
      redBadge = false,
      departureDatePickerText,
      returnDatePickerText,
      interestEvent = 'Interest in Home',
      // Second Redirect Props (It will be removed when isnt used anymore)
      secondRedirectUrl,
      redirectCities,
      secondRedirectEnabled,
      dateFormat = 'dd-mmm-yy',
      brand,
      growthbook,
      growthbookStandalone,
      growthbookDev,
      whatsappUrl = '',
      contrast = false,
      maxRecentSearches = 3,
      reverseVariant = false,
      whatsappVariant = false,
      geolocationOrigin,
      orderByGeoLocation,
      allowFetch,
      flatVariant = false,
      showNearestTerminal,
      filters,
      triggerSearchEvent = 'false',
      profileAutocomplete,
      profileEnabled,
      profileUrl,
      groupPlaces,
      recommentRoutes = '',
      passengersOptions = '',
      seasonPassengers = '',
      maxPassengers = 5,
      passengersValues = '',
      recentSearchesLS = 'recentSearches',
      freezeInitial = false,
    } = this.props;

    const hexColorRegex = /^#(?:[0-9a-f]{3}){1,2}$/i;
    const accentColorIsValid = !accentColor || hexColorRegex.test(accentColor);
    const buttonColorIsValid = !buttonColor || hexColorRegex.test(buttonColor);
    const buttonBgIsValid = !buttonBg || hexColorRegex.test(buttonBg);
    const maxDaysSearchIsValid = !Number.isNaN(Number(maxDaysSearch));

    if (!maxDaysSearchIsValid) {
      console.error('max-days-search is not a number, the default (30) will be used');
    }

    if (!accentColorIsValid) {
      console.error('accent-color is invalid, the default (#EC008C) will be used');
    }

    if (!buttonColorIsValid) {
      console.error('button-color is invalid, the default (#FFFFFF) will be used');
    }

    if (!buttonBgIsValid) {
      console.error('button-bg is invalid, the default (#FFFFFF) will be used');
    }

    const abTests = this.getAbTests();
    this.config = {
      // prefill
      departureDate,
      destination,
      origin,
      passengers,
      returnDate,
      // URL
      forwardQuery,
      useUtmFor,
      utmParams: {
        campaign: utmCampaign,
        medium: utmMedium,
        source: utmSource,
        content: utmContent,
      },
      forceHttps: forceHttps === '' || forceHttps === 'true',
      // behaviour
      searchTypes,
      hotelsUrl,
      hotelsResultsType,
      packagesUrl,
      Autocomplete: autocompleteType === 'typeahead' ? AutocompleteTypeahead : AutocompleteSelect,
      optInReturn: optInReturn === '' || optInReturn === 'true',
      showOpenTicket: showOpenTicket === '' || showOpenTicket === 'true',
      passengersDropdown: passengersDropdown === '' || passengersDropdown === 'true',
      openNewWindow: openNewWindow === '' || openNewWindow === 'true',
      maxDaysSearch: maxDaysSearchIsValid ? Number(maxDaysSearch) : 30,
      calendarOpen: calendarOpen === '' || calendarOpen === 'true',
      allowHybridTrip: allowHybridTrip === '' || allowHybridTrip === 'true',
      multiOpenTicket,
      // visualization
      buttonColor,
      buttonBg,
      accentColor,
      widgetTitle,
      widgetLayout,
      brandingCopy,
      showErrors: showErrors === '' || showErrors === 'true',
      // general
      line,
      airline,
      transporter,
      sourceUrl,
      funnelUrl: window && window.location.hostname === 'localhost' ? 'localhost:3000' : funnelUrl,
      useGtm: useGtm === '' || useGtm === 'true',
      displayType,
      lang,
      autoSelectOrigin: autoSelectOrigin === '' || autoSelectOrigin === 'true',
      ...abTests,
      cardsAvailable: cardsAvailable === '' || cardsAvailable === 'true',
      oxxoAvailable: oxxoAvailable === '' || oxxoAvailable === 'true',
      paypalAvailable: paypalAvailable === '' || paypalAvailable === 'true',
      coppelpayAvailable: coppelpayAvailable === '' || coppelpayAvailable === 'true',
      discountSingle,
      discountRounded,
      discountOpenTicket,
      redBadge: redBadge === '' || redBadge === 'true',
      departureDatePickerText,
      returnDatePickerText,
      interestEvent,
      filters,
      // Vanda Props
      secondRedirectUrl,
      redirectCities,
      secondRedirectEnabled,
      dateFormat,
      brand,
      growthbook,
      growthbookStandalone: growthbookStandalone === '' || growthbookStandalone === 'true',
      growthbookDev: growthbookDev === '' || growthbookDev === 'true',
      whatsappUrl,
      whatsappVariant: whatsappVariant === '' || whatsappVariant === 'true',
      flatVariant: flatVariant === '' || flatVariant === 'true',
      contrast: contrast === '' || contrast === 'true',
      growthbookFeaturesEnabled,
      maxRecentSearches: maxRecentSearches !== '' ? Number(maxRecentSearches) : 3,
      reverseVariant,
      geolocationOrigin: geolocationOrigin === '' || geolocationOrigin === 'true',
      orderByGeoLocation: orderByGeoLocation === '' || orderByGeoLocation === 'true',
      allowFetch: allowFetch === '' || allowFetch === 'true',
      showNearestTerminal: showNearestTerminal === '' || showNearestTerminal === 'true',
      triggerSearchEvent: triggerSearchEvent === '' || triggerSearchEvent === 'true',
      profileUrl,
      profileEnabled: profileEnabled === '' || profileEnabled === 'true',
      profileAutocomplete: profileAutocomplete === '' || profileAutocomplete === 'true',
      groupPlaces: groupPlaces === '' || groupPlaces === 'true',
      recommentRoutes,
      passengersForSelector:
        passengersDropdown !== 'false'
          ? getPassengersSelectorList({
              passengers: passengersOptions.split(',')?.map(p => p.trim()),
              lang,
            })
          : [],
      seasonPassengersForSelector:
        passengersDropdown !== 'false'
          ? getPassengersSelectorList({
              passengers: seasonPassengers.split(',')?.map(p => p.trim()),
              lang,
            })
          : [],
      passengersValues:
        passengersValues && decodePassengersValues({ passengers: passengersValues }),
      maxPassengers: maxPassengers !== '' ? Number(maxPassengers) : 5,
      recentSearchesLS,
      freezeInitial: freezeInitial === '' || freezeInitial === 'true',
    };
  }

  getAbTests() {
    const availableABTests = [
      'paymentBenefitsAB',
      'abtestDestinationHit',
      'abtestAutoSelectOrigin',
      'discountsAB',
      'textPromAB',
    ];
    const { abTests } = this.props;
    if (!abTests) return;
    const abTestsObject = {};
    abTests.split(',').forEach(ab => {
      const isValid = availableABTests.includes(ab);
      if (!isValid) {
        console.error(`${ab} AB test is not valid`);
      } else if (availableABTests.includes(ab)) abTestsObject[ab.trim()] = true;
    });
    // eslint-disable-next-line consistent-return
    return abTestsObject;
  }

  /** Gets the growthbook features passed by props */
  setAvailableGrowthbookFeatures() {
    const { brand } = this.props;
    this.setState({
      growthbookFeatures: getGrowthBookFeatures(this.growthbookFeatures, brand),
    });
  }

  initTest() {
    const { abtestProbability, abtestTarget } = this.props;
    const abtest = createUrl().queryParams['ab-test'];

    if (!abtestProbability) return;

    if (abtest === 'a' || abtest === 'b') {
      this.abtest = abtest;
    } else {
      const probability = abtestProbability ? Number(abtestProbability) : NaN;
      this.abtest = Number.isNaN(probability) ? getTest() : initTest(probability);
    }

    emitTest(this.abtest);

    // debugger;
    if (abtestTarget) {
      if (this.abtest === 'a') {
        $(abtestTarget).hide();
      } else {
        $(abtestTarget).show();
      }
    }
  }

  initLanguage() {
    const { lang } = this.props;
    const isSupported = supportedLang.indexOf(lang) > -1;
    if (!isSupported)
      console.error(`Language ${lang} not supported. Supported Languajes: ${supportedLang}`);

    const selectedLang = isSupported ? lang : DEFAULT_LANG;
    widgetConfig.i18nLng = selectedLang;
    dayjs.locale(selectedLang, dayjsTranslations[selectedLang] || dayjsTranslations[DEFAULT_LANG]);
    $.extend($.fn.pickadate.defaults, datepickerTranslations[selectedLang]);
  }

  createTabsInfo() {
    const { searchTypes } = this.config;
    const { userProfile } = this.state;
    let types = searchTypes.split(',').map(type => type.trim());
    const [firstType] = types;
    if (firstType === 'all') {
      types = ['bus', 'packages', 'hotel'];
    }
    return types.reduce((accumulator, currentType) => {
      switch (currentType) {
        case 'bus':
          accumulator.push({
            title: 'trips',
            icon: 'travel',
            component: <Search profile={userProfile} />,
          });
          break;
        case 'packages':
          accumulator.push({
            title: 'travels',
            icon: 'packages',
            component: <PackagesSearch config={this.config} />,
          });
          break;
        case 'hotel':
          accumulator.push({
            title: 'hotels',
            icon: 'hotels',
            component: <HotelSearch config={this.config} />,
          });
          break;
        default:
          return accumulator;
      }

      return accumulator;
    }, []);
  }

  render({ compactHeight }) {
    if (this.abtest !== 'a') return null;
    this.setConfig();

    const { widgetLayout } = this.config;
    const { activeTab, growthbookFeatures } = this.state;

    const tabsData = this.createTabsInfo();

    return (
      <WidgetConfigProvider config={this.config}>
        <GrowthBookFeaturesProvider features={growthbookFeatures || {}}>
          <UserPreferencesProvider config={this.config}>
            <IntlProvider definition={translations[widgetConfig.i18nLng]}>
              <div
                className={`reservamos-search ${widgetLayout} ${
                  compactHeight === '' ? 'compact-height' : ''
                }`}
              >
                {tabsData.length > 1 && (
                  <Tabs
                    activeTab={activeTab}
                    onChangeTab={newTab => this.setState({ activeTab: newTab })}
                  >
                    {tabsData.map(tab => (
                      <Tab key={tab.title} title={tab.title} icon={tab.icon}>
                        {tab.component}
                      </Tab>
                    ))}
                  </Tabs>
                )}
                {tabsData.length === 1 && tabsData[0].component}
              </div>
            </IntlProvider>
          </UserPreferencesProvider>
        </GrowthBookFeaturesProvider>
      </WidgetConfigProvider>
    );
  }
}

export default SearchHub;
