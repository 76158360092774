import titleize from '../../utils/titleize';
import { Fragment } from 'preact';

export function formatCityDisplay(isPackagesSearch, place) {
  const cityDisplay = isPackagesSearch ? '' : `, ${titleize(place.display)}`;
  return `${titleize(place.city_name)}${cityDisplay}`;
}

export function formatStateDisplay(place) {
  return titleize(`${place.display}, ${place.state}`);
}

export function formatTerminalCityDisplay(place) {
  return titleize(`${place.display}, ${place.city_name}`);
}

export function formatOnlyTerminalDisplay(place) {
  return titleize(place.display);
}

export function formatDisplayType(displayType, place, isPackagesSearch) {
  switch (displayType) {
    case 'city':
    case 'city_state':
      return formatCityDisplay(isPackagesSearch, place);

    case 'terminal_city':
      return formatTerminalCityDisplay(place);

    case 'state':
      return formatStateDisplay(place);

    case 'only_terminal':
      return formatOnlyTerminalDisplay(place);

    default:
      return formatStateDisplay(place);
  }
}

export function formatRecommendedDisplayType(place, displayType) {
  const formatted = formatDisplayType(displayType, place, false);
  return place.city_name ? (
    <Fragment>
      {formatted.split(',')[0]} <span>{formatted.split(',')[1]?.trim()}</span>
    </Fragment>
  ) : (
    <Fragment>{place.name}</Fragment>
  );
}

export const createPlace = place => ({
  city_name: place.cityName,
  display: place.display,
  state: place.state,
});
