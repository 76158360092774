import addDate from './addDate';
import stringToDate from './stringToDate';

export default function validateDate(stringDate, maxDaysSearch, openTicketText, dateFormat) {
  // If date is boleto abierto, return true
  // Should also check in different languages
  if (stringDate === openTicketText) return true;

  const dateToValidate = stringToDate(stringDate, dateFormat);
  if (isNaN(dateToValidate)) return false; // Check for invalid date

  // Getting the date without time to take the today date with the 00:00 hour
  const now = new Date(new Date().setHours(0, 0, 0, 0));

  const maxDate = addDate(new Date(), maxDaysSearch);

  if (dateToValidate.getTime() < now.getTime()) return false;

  if (dateToValidate.getTime() > maxDate.getTime()) return false;

  return true;
}
