import dayjs from 'dayjs';
import 'script-loader!pickadate/lib/compressed/picker';
import 'script-loader!pickadate/lib/compressed/picker.date';

const dateFormatParsers = {
  'dd-mmm-yy': stringDate => {
    const [day, month, year] = dayjs(stringDate, 'DD-MMM-YY')
      .format('DD-MM-YYYY')
      .split('-');

    return new Date(Number(year), Number(month) - 1, Number(day));
  },
  'yyyy-mm-dd': stringDate => {
    const [year, month, day] = stringDate.split('-');
    const monthIndex = Number(month) - 1;
    return new Date(year, monthIndex, day);
  },
};

export default function stringToDate(string, format = 'dd-mmm-yy') {
  const dateFormatParser = dateFormatParsers[format];
  if (!string || !dateFormatParser) return null;

  return dateFormatParser(string);
}
